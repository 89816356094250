<template>
    <div v-if="!access">
        <NotFound />
    </div>
    <div v-else>
        <div class="pb-2 mb-3 border-bottom">
            <h2>Payroll Formulas</h2>
        </div>
        <nav aria-label="breadcrumb">
            <ol class="breadcrumb">
                <li class="breadcrumb-item">
                    <router-link to="/">Dashboard</router-link>
                </li>
                <li class="breadcrumb-item">
                    <router-link :to="{name: 'earning-deductions'}">Earning Deductions</router-link>
                </li>
                <li class="breadcrumb-item active" aria-current="page">Payroll Formulas</li>
            </ol>
        </nav>
        <b-container fluid>
            <div v-if="api_error">
                <b-alert
                    :show="alertDismissCountDown"
                    dismissible
                    variant="danger"
                    @dismissed="alertDismissCountDown=0"
                    @dismiss-count-down="alertCountDownChanged"
                >{{api_error}}</b-alert>
            </div>
            <b-row>
                <b-col lg="6" class="mb-1rem">
                    <router-link
                        v-if="hasPermission('CAN_MANAGE_PAYROLL_FORMULAS')"
                        :to="{name: 'create-payroll-formula'}"
                        class="btn btn-primary text-right"
                        tag="button"
                    >
                        <font-awesome-icon icon="plus"></font-awesome-icon>&nbsp; Create Payroll Formula
                    </router-link>
                </b-col>
            </b-row>

            <b-table
                show-empty
                striped
                hover
                :items="items"
                :fields="fields"
                no-local-sorting
                @sort-changed="customSorting"
                @filtered="onFiltered"
                responsive
            >
                <template v-slot:cell(name)="row">
                    {{row.item.name}} <span v-for="defaultFormula in default_formula_variables" :key="defaultFormula"> <span class="text-danger" style="font-weight:bold;" v-if="row.item.slug == defaultFormula ">*</span> </span> 
                </template>
                <template v-slot:cell(conditions)="row">
                    <div class="row">
                        <div class="col-md-12">
                            <span v-if="JSON.parse(row.item.conditions).length>1">Default Formula : </span>{{ JSON.parse(row.item.conditions)[JSON.parse(row.item.conditions).length-1].formula }}
                        </div>
                    </div>
                    <div class="row" :class="{'mt-3':(JSON.parse(row.item.conditions).length-1)>index}" v-for="(condition, index) in JSON.parse(row.item.conditions).slice(0, JSON.parse(row.item.conditions).length-1)" :key="index">
                        <div class="col-md-12" v-for="(value,name,index2) in condition" :key="index2">
                            <span>{{ str_title(name) }} {{ index+1 }}: {{ value }}</span>
                        </div>
                    </div>
                </template>
                <template v-slot:cell(actions)="row">
                    <span class="actions">
                        <router-link
                            class="circle"
                            v-b-tooltip.hover title="Edit"
                            :to="{ name: 'edit-payroll-formula', params: { id:  row.item.id}}"
                        >
                            <font-awesome-icon
                                v-if="hasPermission('CAN_MANAGE_PAYROLL_FORMULAS')"
                                icon="pen"
                            ></font-awesome-icon>
                        </router-link>
                        <span v-b-tooltip.hover class="circle ml-1" @click="openHistory(row.item)" title="history"> 
                            <font-awesome-icon
                                icon="history" 
                                style="cursor: pointer;"
                            />
                        </span>
                    </span>
                </template>
            </b-table>
        </b-container>


        <!-- History modal -->
            <b-modal 
                :id="history.id" 
                centered  
                size="xl" 
                hide-footer
            >
            <template #modal-title>
               <span class="h4 font-weight-bold">{{history.title}}</span> 
            </template>
            <b-table
                show-empty
                striped
                hover
                :items="history.rows"
                :fields="history.fields"
                no-local-sorting
                responsive
            >
                <template v-slot:cell(conditions)="row">
                    <div class="row">
                        <div class="col-md-12">
                            <span v-if="JSON.parse(row.item.conditions).length>1">Default Formula : </span>{{ JSON.parse(row.item.conditions)[JSON.parse(row.item.conditions).length-1].formula }}
                        </div>
                    </div>
                    <div class="row mt-3" v-for="(condition, index) in JSON.parse(row.item.conditions).slice(0, JSON.parse(row.item.conditions).length-1)" :key="index">
                        <div class="col-md-12" v-for="(value,name,index2) in condition" :key="index2">
                            <span>{{ str_title(name) }} {{ index+1 }}: {{ value }}</span>
                        </div>
                    </div>
                </template>
            </b-table>
        </b-modal>
    </div>
</template>

<script>
import NotFound from "../../../errors/NotFound";
import default_error from "../../../../assets/js/global";
export default {
    components: {
        NotFound
    },
    data() {
        return {
            urlBody: {
                query: "",
                sort_by: "id",
                order:"desc",
                page: 1,
                per_page: 10,
            },
            api_error: "",
            sortBy: "id",
            sortDesc: false,
            fields: [
                { key: "name", sortable:true,
                    formatter: value => {
                        return value != null
                            ? value.charAt(0).toUpperCase() + value.slice(1)
                            : "";
                    }
                },
                { key: "slug", sortable:false},
                { label: "Formula", key: "conditions", sortable:false, tdClass: 'tableWidth'},
                { key: "actions", label: "Actions"}
            ],
            items: null,
            total_rows: 1,
            lastPage: 1,
            pageOptions: [10, 25, 50, 100],
            filter: null,
            infoModal: {
                id: "modal-sm",
                title: "",
                content: ""
            },
            access: true,
            alertDismissCountDown: 0,
            alertDismissSecs: 10,
            message:"",
            history: {
                id: 'history-modal',
                title: 'History',
                fields: [
                    { key: "name", sortable:false },
                    { label: "Formula", key: "conditions", sortable:false},
                    { key: "updated_by_name", label:"Updated By", sortable:false, class: "text-nowrap"},
                    { key: "updated_at", label:"Updated At", sortable:false, class: "text-nowrap"},
                ],
                rows: []
            }
        };
    },
    mounted() {
        this.access = this.hasPermission("CAN_MANAGE_PAYROLL_FORMULAS");
        this.default_formula_variables = this.$store.getters.getAppSetting("payroll", "pre_calculated_variables");
        this.onPageLoad();
    },
    methods: {
        alertCountDownChanged(alertDismissCountDown) {
            this.alertDismissCountDown = alertDismissCountDown;
        },
        showAlert() {
            this.alertDismissCountDown = this.alertDismissSecs;
        },
        onPageLoad() {
            let api_params =
                Object.keys(this.$route.query).length > 0
                    ? this.$route.query
                    : this.getDefaultFilters();
            api_params = this.deleteEmptyKeys(this.$route.query);
            this.urlBody.page = api_params.page
                ? api_params.page
                : this.urlBody.page;
            this.urlBody.per_page = api_params.per_page
                ? api_params.per_page
                : this.urlBody.per_page;
            this.urlBody.sort_by = api_params.sort_by
                ? api_params.sort_by
                : this.urlBody.sort_by;
            this.urlBody.order = api_params.order
                ? api_params.order
                : this.urlBody.order;
            this.getParollFormulas(this.urlBody);
        },

        getDefaultFilters() {
            let api_params = {
                page: this.urlBody.page,
                per_page: this.urlBody.per_page,
                sort_by: this.urlBody.sort_by,
                order: this.urlBody.order,
            };
            return api_params;
        },

        convertTimeFormat(time){
            time = time.toString ().match (/^([01]\d|2[0-3])(:)([0-5]\d)(:[0-5]\d)?$/) || [time];
            if (time.length > 1) { 
                time = time.slice (1);  
                time[5] = +time[0] < 12 ? ' AM' : ' PM';
                time[0] = +time[0] % 12 || 12; 
            }
            return time.join('');
        },

        info(item, index, button) {
            this.infoModal.title = `Delete Confirmation`;
            this.message = 'Are you sure you want to Delete <b>'+item.name+'</b>?';
            this.infoModal.content = JSON.stringify(item, null, 2);
            this.$root.$emit("bv::show::modal", this.infoModal.id, button);
        },

        resetInfoModal() {
            this.infoModal.title = "";
            this.infoModal.content = "";
        },

        onSelection() {
            var query =this.deleteEmptyKeys(this.urlBody);
            this.getParollFormulas(query);
        },

        onFiltered() {
            if (this.filter) {
                this.getParollFormulas({query:this.filter});
            }
         
        },

        getParollFormulas: function(params) {
            var query = this.getAuthHeaders();
            query["params"] = this.deleteEmptyKeys(params);
            this.axios
                .get(this.$api.get_payroll_formulas, query)
                .then(response => {
                    let result = response.data;
                    this.items = result.data;
                    this.urlBody.page = result.current_page;
                    this.urlBody.per_page = result.per_page;
                    this.lastPage = result.last_page;
                    this.total_rows = result.total;
                    this.api_error = "";
                    this.updateProgressBar(true);
                    this.$router
                        .push({
                            path: this.$route.params[0],
                            query: this.deleteEmptyKeys(params)
                        }).catch(()=>{})
                })
                .catch(err => {
                    if (!err.response) {
                        this.api_error = default_error.server_error;
                    } else if (
                        err.response &&
                        err.response.data &&
                        err.response.data.message
                    ) {
                        this.api_error = err.response.data.message;
                    } else {
                        this.api_error = default_error.server_error;
                        console.error(err.response.data);
                    }
                    this.items = [];
                    this.updateProgressBar(false);
                    this.showAlert();
                });
        },

        customSorting: function(ctx) {
            if (ctx.sortBy == "" || ctx.sortBy == null) {
                return;
            }
            let urlBody = this.deleteEmptyKeys(this.$route.query);
            urlBody.order = ctx.sortDesc ? "asc" : "desc";
            urlBody.sort_by = ctx.sortBy;
            urlBody.page = 1;
            this.api_error = "";
            this.getParollFormulas(urlBody)
        },

        openHistory(item) {
            this.history.rows = JSON.parse(item.history);
            this.$root.$emit("bv::show::modal", this.history.id);
        }
    }
};
</script>
<style lang="scss">
@import "../../../../assets/css/custom.scss";

#history-modal .modal-body {
    padding: 0 !important;
}
.tableWidth {
    max-width: 350px;
}
</style>
